import React from 'react';
import { withRouter } from 'react-router';
import { ModalDialog } from '@launchpad';
import validation from 'validate.js';
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';
import BootstrapDateTimePicker from 'eonasdan-bootstrap-datetimepicker';
import DatetimepickerCSS from '../../../styles/bootstrap/bootstrap-datetimepicker.css';
import AppSelect from '../AppSelect';
import { AppIcon } from '@launchpad';

class AppTableFilter extends React.Component {
  _debounceFilter = _.debounce(() => this._triggerFilter(), 200);

  constructor(props) {
    super(props);

    this.state = {
      filter: this._getInitialFilter(props),
      showFilterModal: false,
      errors: []
    };

    props.onInit(this);
  }

  componentDidMount() {
    this.attachDateTimeDropdown();
  }

  getFilter() {
    return this.state.filter;
  }

  /**
   *
   * @param {Object} props Passed props
   */
  attachDateTimeDropdown() {
    $('.filter-datetime').each((index, element) => {
      $(element)
        .datetimepicker({
          useCurrent: false,
          format: this.props.format,
          maxDate: moment().endOf('day'),
          widgetPositioning: {
            horizontal: this.props.horizontalCalendarPosition || 'auto',
            vertical: this.props.verticalCalendarPosition || 'auto'
          }
        })
        .on('dp.change', e => {
          const filter = this.state.filter[
            $(e.target)
              .closest('.single-filter-field')
              .data('index')
          ];
          if (!filter || filter.length === 0) return;

          const value = e.date.format ? e.date.format(this.props.format) : null;
          if (e.date) {
            setTimeout(() => {
              $(element)
                .data('DateTimePicker')
                .hide();
              this._changedFilter(filter, value, false, e.date.toDate());
            }, 50);
          }
        });
    });
  }

  _getInitialFilter(props) {
    let filter = null;
    if (props.location.query.value) {
      filter = this._joinFilters(props.filter, props.location.query.value);
    } else {
      filter = props.filter;
    }

    return this._prepareFilter(filter);
  }

  _joinFilters(filter, otherFilters) {
    const mainFilter = [...filter];

    otherFilters.forEach(otherFilter => {
      const existingFilter = mainFilter.find(x => x.name === otherFilter.name);
      if (existingFilter) {
        const indexOf = mainFilter.indexOf(existingFilter);
        mainFilter[indexOf] = otherFilter;
        // mainFilter[indexOf] = {...existingFilter, ...otherFilter};
      } else {
        mainFilter.push(otherFilter);
      }
    });
    return mainFilter;
  }

  _prepareFilter(filters) {
    if (!filters) return [];

    filters.forEach((filter, index) => {
      if (
        filters[index].value === null ||
        typeof filters[index].value === 'undefined'
      ) {
        filters[index].value = '';
      }
    });

    return filters;
  }

  _getElements(props) {
    const { modalFilters } = this.props;

    const elements = [];

    if (!props.filter || props.filter.length === 0) {
      return elements;
    }

    props.filter.forEach((singleFilter, index) => {
      let innerElement = null;
      switch (singleFilter.type) {
        case 'term':
          innerElement = this._getTermField(singleFilter, index);
          break;
        case 'datetime':
          innerElement = this._getDatetimeField(singleFilter, index);
          break;
        case 'select':
          innerElement = this._getSelectField(singleFilter, index);
          break;
        default:
          return;
      }

      const fieldErrors =
        this.state.errors && this.state.errors[singleFilter.name];

      if (innerElement) {
        elements.push(
          <>
            <div
              style={{
                display: 'inline-block',
                width: modalFilters ? '33%' : 'auto',
                zIndex: 999 - index
              }}
              className="single-filter-field"
              data-index={index}
              key={`filter${index}`}
            >
              {innerElement}
              {fieldErrors && (
                <div>
                  {fieldErrors?.map(e => (
                    <p
                      style={{
                        color: '#b74343',
                        marginTop: -16,
                        fontSize: 12
                      }}
                    >
                      {e}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </>
        );
      }
    });

    return elements;
  }

  _getTermField(singlefilter, index) {
    const label = singlefilter.label ? singlefilter.label : null;
    const { modalFilters } = this.props;
    return (
      <div className="filter-field" data-index={index}>
        <label>{label}</label>
        <input
          type={singlefilter.termType || 'search'}
          placeholder="Search"
          value={
            this.state.filter[index] ? this.state.filter[index].value : null
          }
          onChange={e => {
            this._changedFilter(singlefilter, e.target.value);
            if (!modalFilters) {
              this._debounceFilter();
            }
          }}
        />
      </div>
    );
  }

  _getDatetimeField(singleFilter, index) {
    const label = singleFilter.label ? singleFilter.label : singleFilter.name;
    return (
      <div className="filter-field" data-index={index} key={`datetime${index}`}>
        <label>{label}</label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            key={`datetimeInput${index}`}
            className="filter-datetime"
            type="text"
            placeholder=""
            value={
              this.state.filter[index] ? this.state.filter[index].value : null
            }
            onChange={e => {
              this._changedFilter(singleFilter, e.target.value);
            }}
          />
          {this.state.filter[index] && this.state.filter[index].value && (
            <>
              <AppIcon
                onClick={() => this._changedFilter(singleFilter, '')}
                name="fa.times-circle"
                style={{
                  fontSize: 20,
                  marginLeft: 5,
                  color: '#white'
                }}
              />
            </>
          )}
        </div>
      </div>
    );
  }

  _getSelectField(singleFilter, index) {
    const label =
      singleFilter.label !== true ? singleFilter.label : singleFilter.name;
    let disableUnderline = true;

    if (!singleFilter.options.disableUnderline) {
      disableUnderline = false;
    } else {
      disableUnderline = true;
    }

    return (
      <div
        className="filter-field app-select"
        data-index={index}
        // Workaround for appTable, because AppSelect has hardcoded marginBottom: 20
        style={{ marginBottom: -20, ...singleFilter.style }}
      >
        {singleFilter.options.hideLabel !== true && <label>{label}</label>}
        <AppSelect
          textFieldProps={{
            disableUnderline,
            label: false,
            InputLabelProps: {
              shrink: true
            }
          }}
          {...singleFilter.options}
          options={[
            { id: '', name: '- empty -' },
            ...singleFilter.options.options
          ]}
          onChange={itemId => {
            this._changedFilter(singleFilter, itemId, true);
          }}
          value={
            this.state.filter[index] ? this.state.filter[index].value : null
          }
        />
      </div>
    );
  }

  _changedFilter(filter, filterValue, reload = false, rawValue = null) {
    const { modalFilters } = this.props;

    const filterCopy = [...this.state.filter];
    let currentFilter = null;

    if (filter.name) {
      currentFilter = filterCopy.find(x => x.name === filter.name);
    } else {
      currentFilter = filterCopy.find(x => x.field === filter.field);
    }

    if (currentFilter) {
      currentFilter.value = filterValue;
      currentFilter.rawValue = rawValue;
      filterCopy[filterCopy.indexOf(currentFilter)] = currentFilter;
    } else {
      filterCopy.push({
        ...filter,
        value: filterValue,
        rawValue
      });
    }

    this.setState(
      {
        filter: filterCopy
      },
      () => {
        if (reload && !modalFilters) {
          this._triggerFilter();
        }
      }
    );
  }

  _triggerFilter() {
    // this.props.filtersValidation.forEach(fv => {

    // })
    const keyValuePairs = {};
    this.state.filter.forEach(f => {
      keyValuePairs[f.name] = f.rawValue || f.value;
    });
    const errors = validation.validate(
      keyValuePairs,
      this.props.filtersValidation,
      {
        fullMessages: false
      }
    );
    this.setState({
      errors
    });
    if (!errors) {
      if (this.props.modalFilters) {
        this.setState({ showFilterModal: false });
      }
      this.props.onFilter(this.state.filter);
    }
  }

  _clearFilter() {
    const { modalFilters } = this.props;

    const newFilter = [...this.state.filter];
    newFilter.forEach((filter, index) => {
      newFilter[index].value = '';
      newFilter[index].rawValue = null;
    });

    this.setState(
      {
        filter: newFilter
      },
      () => {
        if ($('.filter-datetime').length) {
          $('.filter-datetime')
            .data('DateTimePicker')
            .clear();
        }
        if (!modalFilters) {
          this.props.onFilter(this.state.filter);
        }
      }
    );
  }

  _getClearFilterButton() {
    let allEmpty = true;

    this.state.filter.forEach(filter => {
      if (filter.value) allEmpty = false;
    });

    if (allEmpty) return null;

    return (
      <button
        type="button"
        className="button-info mx-1 my-0"
        onClick={() => this._clearFilter()}
      >
        Clear
      </button>
    );
  }

  render() {
    const { formHandler } = this;

    const elements = this._getElements(this.props);

    if (elements.length === 0) return null;

    const clearFilterButton = this._getClearFilterButton();
    const { modalFilters } = this.props;

    const buttons = (
      <>
        {clearFilterButton}
        {this.props.noSearchButton ? null : (
          <button
            type="submit"
            className="button-round mx-1 my-0"
            onClick={e => {
              e.preventDefault();
              this._triggerFilter();
              return false;
            }}
          >
            Search
          </button>
        )}
      </>
    );

    const form = (
      <form
        onSubmit={() => this._triggerFilter()}
        style={{
          padding: this.props.padded ? 10 : 0,
          display: 'flex',
          // alignItems: 'center'
          alignItems: 'baseline',
          flexWrap: modalFilters ? 'wrap' : null,
          justifyContent: modalFilters ? 'flex-start' : null
        }}
        className={`app-table-filter ${
          this.props.padded ? 'app-table-filter-padded' : ''
        } ${this.props.filterClass}`}
        formHandler={formHandler}
      >
        {elements}
        {modalFilters ? (
          <div className="w-100 mt-2 footer-buttons">{buttons}</div>
        ) : (
          buttons
        )}
      </form>
    );

    return (
      <>
        {modalFilters ? (
          <>
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="button-info m-3"
                style={{ minWidth: 100 }}
                onClick={() => {
                  this.setState({ showFilterModal: true }, () => {
                    setTimeout(() => {
                      this.attachDateTimeDropdown();
                    }, 100);
                  });
                }}
              >
                {this.getFilter().filter(f => f.value).length
                  ? 'Edit'
                  : '+ Add'}{' '}
                filters
              </button>
              {this.getFilter().filter(f => f.value).length > 0 ? (
                <button
                  type="button"
                  className="button-danger m-3"
                  style={{ minWidth: 100 }}
                  onClick={() => {
                    this._clearFilter();
                    this._triggerFilter();
                  }}
                >
                  Clear all
                </button>
              ) : null}
              {!this.state.showFilterModal && (
                <div className="d-flex align-items-center flex-wrap">
                  {this.getFilter()
                    .filter(f => f.value)
                    .map(f => (
                      <p
                        className="ml-1 mb-0 p-1 px-3 my-1"
                        style={{
                          backgroundColor: '#c3c3c3',
                          borderRadius: 15
                        }}
                      >
                        {`${f.label}: `}
                        <span
                          style={{
                            textTransform: 'capitalize'
                          }}
                        >{`${f.value}`}</span>
                      </p>
                    ))}
                </div>
              )}
            </div>
            <ModalDialog
              customContentStyle="remove-overflow filters-modal"
              show={this.state.showFilterModal}
              onClose={() => this.setState({ showFilterModal: false })}
            >
              {form}
            </ModalDialog>
          </>
        ) : (
          form
        )}
      </>
    );
  }
}

AppTableFilter.defaultProps = {
  onInit: () => {},
  onFilter: () => {},
  format: 'DD/MM/YYYY HH:mm:ss'
};

export default withRouter(AppTableFilter);
