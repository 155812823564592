import BaseModel from '@launchpad/logic/model/BaseModel';
import ValidateEmailService from './ValidateEmailService';

export default class LoginModel extends BaseModel {
  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'auth/session/login';
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      password: {
        presence: {
          message: '^Please enter a password'
        },
        length: {
          minimum: 5,
          message: '^Your password must be at least 5 characters long'
        }
      }
    };
  }

  customValidation(record) {
    try {
      ValidateEmailService.validateEmail(record.username);
    } catch (e) {
      return [
        {
          field: 'username',
          message: e.message
        }
      ];
    }

    return [];
  }
}
