import BaseModel from '../../../logic/model/BaseModel';

export default class EventsModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'request/logs/events';
  }

  /**
   * Get details url
   */
  getDetailsUrl(id) {
    return `request/logs/events/${id}`;
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: true,
        type: 'field'
      },
      {
        name: 'eventName',
        label: 'Name',
        sortable: false,
        type: 'field'
      },
      {
        name: 'osType',
        label: 'OS type',
        sortable: false,
        type: 'field'
      },
      {
        name: 'presentationStyle',
        label: 'Risk level',
        sortable: false,
        type: 'field'
      },
      {
        name: 'ipAddress',
        label: 'IP Address',
        sortable: false,
        type: 'field'
      },
      {
        name: 'user',
        label: 'Username',
        sortable: false,
        type: 'field'
      },
      {
        name: 'url',
        label: 'Url',
        sortable: false,
        type: 'field'
      },
      {
        name: 'logTimestamp',
        label: 'Log timestamp',
        sortable: false,
        type: 'field'
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions'
      }
    ];
  }
}
