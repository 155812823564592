import React from 'react';
import { AppPage, AppWidget } from '../../../../../@launchpad/components';
import NewBulkLoad from './NewBulkLoad';

export default class BulkLoadDashboard extends React.Component {
  
  constructor() {
    super();
    this.state = {
      result: null
    };
  }

  render() {
    return (
      <AppPage title={'Bulk Load'} icon={'plus'}>
        <div className="row">
          <div className="col-sm-4">
            <AppWidget title="New Load" icon={'plus'}>
              <NewBulkLoad 
                onUpload={result => {
                  this.setState({
                    result
                  });
                }}/>
            </AppWidget>
          </div>
          {this.state.result ? (
            <div className="col-sm-4">
              <AppWidget title="Result" icon={'plus'} padded>
                <pre style={{ color: 'white' }}>
                  {JSON.stringify(this.state.result, null, 2)}
                </pre>
              </AppWidget>
            </div>
          ) : null}
          {/* <div className="col-sm-8">
            <AppWidget title="Previous bulk loads" icon={'list'}>
              <LatestBulkLoads />
            </AppWidget>
          </div> */}
        </div>
      </AppPage>
    );
  }
}
