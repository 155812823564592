import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Api from '@launchpad/logic/api/Api';
import { LoadingOverlay } from '@launchpad';
import CustomerBasicInfo from './tabs/CustomerBasicInfo';
import AccountInfo from './tabs/AccountInfo';
import BreadcrumbHelper from '../../../../../@launchpad/util/BreadcrumbHelper';
import AppTabs from '../../../../../@launchpad/components/crud/tabs/AppTabs';
import Config from '../../../../../@launchpad/config';
import UsersDevicesList from '../../../../../@launchpad/modules/users/user-devices/pages/list/UsersDevicesList';
import EventsList from '../../../../../@launchpad/modules/events/EventsList';

export default class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      // accounts: [],
      user: {},
      account: {},
      activeTab: 'basic',
      loading: false
    };
  }

  componentDidMount() {
    this._getUserDetails();
  }

  getTabs(user, account) {
    const basicInfo = (
      <CustomerBasicInfo onRefresh={() => this._getUserDetails()} user={user} />
    );
    const allTabs = [
      {
        id: 'basic',
        icon: 'info',
        title: 'Basic Info',
        component: basicInfo
      },
      {
        id: 'account',
        icon: 'address-card',
        title: 'Account Info',
        component: (
          <AccountInfo
            onRefresh={() => [
              this._getAllUsers(),
              this._getAllAccounts(),
              this.setState(
                {
                  activeTab: 'basic'
                },
                () => {
                  window.localStorage.setItem(
                    'APP_TABS_ACTIVE_TAB',
                    this.state.activeTab
                  );
                }
              )
            ]}
            user={user}
            account={account}
          />
        )
      },
      {
        id: 'devices',
        icon: 'mobile',
        title: 'Devices',
        component: <UsersDevicesList user={user} {...this.props} />
      },
      {
        id: 'events',
        icon: 'file-text',
        title: 'Events',
        component: <EventsList user={user} justTable {...this.props} />
      }
    ];
    return allTabs;
  }

  getUser() {
    const { id } = this.props.match.params;

    const { items, accounts } = this.state;

    const user = items.find(x => x.id === id);
    // const account = accounts.find(x => x.userUuid === id);

    this.setState({
      user
      // account
    });
  }

  async _getUserDetails() {
    this.setState({
      loading: true
    });
    const { id } = this.props.match.params;
    const getUrl = `admin/users/${id}`;

    try {
      const result = await Api.call(getUrl, 'GET');
      this.setState({
        user: result.data,
        loading: false
      });
    } catch (e) {}
  }

  async _getAllUsers() {
    this.setState({
      loading: true
    });
    const url = new URL(`${Config.url}admin/users`);
    url.search = new URLSearchParams({ page: '1', limit: '', value: '' });

    const getUrl = `admin/users${url.search}`;

    try {
      const result = await Api.call(getUrl, 'GET');

      this.setState(
        {
          items: result.data ? result.data : result,
          loading: false
        },
        () => {
          this.getUser();
        }
      );
    } catch (e) {}
  }

  async _getAllAccounts() {
    const { id } = this.props.match.params;

    this.setState({
      loading: true
    });
    const url = new URL(`${Config.url}admin/accounts`);
    url.search = new URLSearchParams({
      page: '1',
      limit: '',
      value: '',
      userId: id
    });

    const getUrl = `admin/accounts${url.search}`;

    try {
      const result = await Api.call(getUrl, 'GET');

      this.setState({
        // accounts: result.data ? result.data : result,
        account: result.data ? result.data[0] : result[0],
        loading: false
      });
    } catch (e) {}
  }

  /**
   * Render
   *
   * @returns {XML}
   */
  _renderContent(userDetails, accountDetails) {
    if (userDetails.firstName) {
      BreadcrumbHelper.setCrumbs([
        {
          label: 'All Users',
          link: '/',
          goBack: true
        }
      ]);
      BreadcrumbHelper.addCrumb(
        `${userDetails.firstName} ${userDetails.lastName}`
      );
    }
    return (
      <div>
        <h1>
          <i className="fa fa-info" aria-hidden="true" /> User Info:
          <span className="ml-2">
            {userDetails.firstName} {userDetails.lastName}
          </span>
        </h1>
        {this.state.loading ? (
          <LoadingOverlay />
        ) : (
          <AppTabs
            onTabChange={activeTab => this.setState({ activeTab })}
            activeTab={this.state.activeTab}
            tabs={this.getTabs(userDetails, accountDetails)}
          />
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="dashboard-management">
        {this._renderContent(this.state.user, this.state.account)}
      </div>
    );
  }
}

// Inject router
UserDetails.contextTypes = {
  router: PropTypes.object
};
