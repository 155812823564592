// import { IModuleType } from '../../@launchpad/modules/ModulesService';
import EventsModule from '@launchpad/modules/events/EventsModule';
// import LegalModule from '@launchpad/modules/legal/LegalModule';
import AccountsModule from './accounts/AccountsModule';
import CardsModule from './cards/CardsModule';
import ReportsModule from './reports/ReportsModule';
import TransactionsModule from './transactions/TransactionsModule';
import UsersModule from './users/UsersModule';
import NotificationsModule from './notifications/NotificationsModule';

const modules: any = [
  new UsersModule(),
  new AccountsModule(),
  new CardsModule(),
  new TransactionsModule(),
  new ReportsModule(),
  // new LegalModule(),
  new NotificationsModule(),
  new EventsModule()
];

export default modules;
