import React from 'react';
import { Link } from 'react-router-dom';
import LoginPage from '../modules/auth/pages/login/LoginPage';
import ForgotPassword from '../modules/auth/pages/forgot-password/ForgotPassword';
import { CurrentVariant } from './variants';

const overrides = {
  login: {
    page: {
      logo: () => (
        <img className="logo" src={CurrentVariant.logo} alt="bankvault-logo" />
      ),
      text: CurrentVariant.loginText
    }
  },
  card: {
    details: {
      options: {
        load: () => null
      }
    }
  },
  'login-override-page': () => <LoginPage />,
  'forgot-password-override-page': () => <ForgotPassword />,
  sidemenu: {
    logo: () => (
      <div className="dash-logo">
        <img src={CurrentVariant.logo} alt="bankvault-logo" />
      </div>
    )
  },
  'get-user-info': 'auth/user/me',
  'breadcrumb-home-item': () => (
    <Link
      key="linkHome"
      to="/"
      className="breadcrumb-item"
      onClick={() => window.localStorage.setItem('APP_TABS_ACTIVE_TAB', '')}
    >
      Users
    </Link>
  ),
  'no-config-model': () => null,
  'no-account-topbar': () => null,
  // card: {
  //   image: () => (
  //     <img
  //       src={CurrentVariant.cardFront}
  //       style={{ width: '100%', height: 'auto' }}
  //       alt="card"
  //     />
  //   )
  // }
  filterAdminByRoles: (userRoles: any) => {
    const check = arr => arr.every(i => typeof i === 'string');
    // Handle old BE response when roles are returned as strings
    const arrayOfRoles = check(userRoles)
      ? userRoles
      : userRoles.map(ur => (ur.role?.slug ? ur.role?.slug : ur.role?.name));
    if (arrayOfRoles.includes('ab_super_admin')) {
      return { allow: ['*'] };
    } else if (arrayOfRoles.includes('ab_admin')) {
      return {
        allow: ['users.*', 'accounts.*', 'cards.*', 'transactions.*'],
        deny: [
          'users.bulkLoad.*',
          'accounts.bulkLoad.*',
          'transactions.bulkLoad.*'
        ]
      };
    }
    // ab_marketing
    return { allow: ['reports.*', 'legal.*'] };
  }
};

export default overrides;
