import React from 'react';
import qs from 'qs';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Api from '../../logic/api/Api';

export default class EventsDownload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportType: ''
    };
  }

  handleChange = event => {
    this.setState({ reportType: event.target.value });
  };

  async _triggerDownload() {
    if (this.state.downloading) return;
    this.setState({
      downloading: true
    });

    // Build additional filters object
    const additionalFiltersObject = this.props.filter
      .filter(x => x.value && x.value.trim().length > 0)
      .map(x => ({
        [x.name]: x.rawValue ? x.rawValue.toISOString() : x.value
      }));

    const ObjAdditionalFiltersObject = Object.assign(
      {
        entity: this.props.entity ? this.props.entity : undefined,
        entityId: this.props.entityId ? this.props.entityId : undefined,
        accountId: this.props.accountId ? this.props.accountId : undefined,
        paymentDeviceId: this.props.paymentDeviceId
          ? this.props.paymentDeviceId
          : undefined,
        userId: this.props.userId ? this.props.userId : undefined
      },
      ...additionalFiltersObject
    );

    // Build search params
    const searchParams = qs.stringify(ObjAdditionalFiltersObject);

    await Api.downloadFileFromUrl(
      `request/logs/download?reportType=${this.state.reportType}&${searchParams}`,
      'GET'
    );

    setTimeout(() => {
      this.setState({
        downloading: false
      });
      this.props.afterDownloadReport();
    }, 1000);
  }

  _getDownloadButton() {
    let btnClass = 'button';
    if (this.state.reportType === '') {
      btnClass = 'inactive-button';
    }
    return (
      <button
        type="button"
        onClick={() => this._triggerDownload()}
        className={`width-240 m-auto text-center ${btnClass}`}
        disabled={this.state.reportType === ''}
      >
        <i className="fa fa-fw fa-download" />{' '}
        {this.state.downloading ? 'Downloading...' : 'Download'}
      </button>
    );
  }

  render() {
    return (
      <div>
        <h3>Download events</h3>
        <a
          href="javascript:void(0)"
          onClick={() => this.props.closeDownloadReport()}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            right: 20,
            top: 20
          }}
        >
          <i className="fa fa-fw fa-times fa-lg" />
        </a>
        <div
          className="element-with-blur"
          style={{ paddingTop: '2px', marginRight: 20 }}
        >
          <div className="col-10 m-auto py-3 d-flex flex-column align-items-center justify-content-center">
            <p className="text-center">Please select document type:</p>
            <p className="m-0 text-center">
              ( Note: if you used filters, it will be included in the downloaded
              file )
            </p>
            <RadioGroup
              value={this.state.reportType}
              onChange={this.handleChange}
              className="d-flex flex-row align-items-center justify-content-center my-4"
            >
              <FormControlLabel
                value="CSV"
                control={
                  <Radio
                    color="primary"
                    style={{
                      color:
                        this.state.reportType === 'CSV' ? '#0056b3' : '#fff'
                    }}
                  />
                }
                label="CSV"
              />
              <FormControlLabel
                value="XLSX"
                control={
                  <Radio
                    color="primary"
                    style={{
                      color:
                        this.state.reportType === 'XLSX' ? '#0056b3' : '#fff'
                    }}
                  />
                }
                label="XLSX"
              />
            </RadioGroup>
            {this._getDownloadButton(this.state.reportType)}
          </div>
        </div>
      </div>
    );
  }
}
