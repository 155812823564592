import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { currency } from '@launchpad/util/NumberHelper';
import { AppBadge, LoadingOverlay } from '@launchpad';
import { withRouter } from 'react-router';
import Api from '@launchpad/logic/api/Api';
import { LPOverridable } from '../../../../../../@launchpad/components';

class AccountInfo extends Component {
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      loader: false,
      isFetching: true,
      account: {}
    };
  }

  componentDidMount() {
    if (this.props.account) {
      this._getAccountDetails();
    }
  }

  async _getAccountDetails() {
    const { id } = this.props.account;
    const getUrl = `admin/accounts/${id}`;
    if (id !== undefined) {
      try {
        const result = await Api.call(getUrl, 'GET');
        this.setState({
          account: result.data
        });
      } catch (e) {
        console.log('Err:', e);
      } finally {
        this.setState({ isFetching: false });
      }
    } else {
      this.setState({ isFetching: false });
    }
  }

  _renderStatus(item) {
    switch (item.status && item.status.toLowerCase()) {
      case 'active':
      case 'ok':
        return <AppBadge text="Active" icon="check" />;
      case 'locked':
        return <AppBadge text="Locked" icon="lock" type="danger" />;
      case 'inactive':
        return <AppBadge text="Inactive" icon="ban" type="danger" />;
      case 'closed':
        return <AppBadge text="Closed" icon="ban" type="danger" />;
      case 'pending_activation':
        return (
          <AppBadge text="Pending activation" icon="clock-o" type="info" />
        );
      default:
        return null;
    }
  }

  redirectToUserDetails(userId) {
    this.setState(
      {
        loader: true
      },
      () => {
        if (this.props.onRefresh) this.props.onRefresh();
        this.props.history.push(`/user-details/${userId}`);
      }
    );
  }

  render() {
    const { account } = this.state;
    const { user } = this.props;

    if (this.state.isFetching) {
      return (
        <div className="main" style={{ marginTop: 30 }}>
          <LoadingOverlay />
        </div>
      );
    }
    const accountType = account.type
      ? account.type.replaceAll('_', ' ').toUpperCase()
      : '';

    return (
      <div className="main">
        <div className="dark-blur" />
        <div className="customers-info">
          <div className="customer-details">
            <div>
              <h2 className="customer-name">
                {user && Object.keys(user).length !== 0 && (
                  <span className="company">
                    {user && user.firstName && user.lastName
                      ? `${user.firstName} ${user.lastName}`
                      : 'Currently no account information.'}
                  </span>
                )}
              </h2>
            </div>
          </div>

          <div className="customers-main">
            <div className="row">
              <div className="col">
                <div className="padded">
                  <LPOverridable name="user.details.basic.before" user={user} />
                  <div className="row text-white">
                    <div style={{ marginBottom: 20 }} className="col-lg-12">
                      <h4>Account Info</h4>
                      <hr />
                      <dl>
                        <dt>Account number:</dt>
                        <dd>{account.accountNumber || '-'}</dd>
                        <dt>Account id:</dt>
                        <dd>{account.id || '-'}</dd>
                        <dt>Account type:</dt>
                        <dd>{accountType || '-'}</dd>
                        <dt>Status:</dt>
                        <dd>{this._renderStatus(account) || '-'}</dd>
                        <dt>Balance:</dt>
                        <dd>
                          {currency(
                            account.availableBalance
                              ? account.availableBalance
                              : account.balance,
                            account.currency
                          )}
                        </dd>
                        <dt>Last top up:</dt>
                        <dd>{currency(account.lastTopUp, account.currency)}</dd>
                        <dt>Spent today:</dt>
                        <dd>
                          {currency(account.spentToday, account.currency)}
                        </dd>
                        <dt>IBAN:</dt>
                        <dd>{account.iban || '-'}</dd>
                        <dt>Sort code:</dt>
                        <dd>{account.sortCode || '-'}</dd>
                        <dt>Owner Id:</dt>
                        <dd>
                          <button
                            type="button"
                            className="button"
                            onClick={() =>
                              this.redirectToUserDetails(account.ownerId)
                            }
                          >
                            {account.ownerId || '-'}
                          </button>
                        </dd>
                        <dt>User id:</dt>
                        <dd>
                          <button
                            type="button"
                            className="button"
                            onClick={() =>
                              this.redirectToUserDetails(account.userUuid)
                            }
                          >
                            {account.userUuid || '-'}
                          </button>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AccountInfo);
