import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ModalDialog from '@launchpad/components/modal/ModalDialog';
import moment from 'moment';
import AppTable from '../../components/crud/AppTable';
import EventsModel from './models/EventsModel';
import BreadcrumbHelper from '../../util/BreadcrumbHelper';
import EventsDownload from './EventsDownload';

export default class EventsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDownloadModal: false
    };

    if (!props.account && !props.user && !props.card) {
      BreadcrumbHelper.setCrumbs([{ label: 'Events' }]);
    }
  }

  onCloseDownloadModal() {
    this.setState({
      showDownloadModal: false
    });
  }

  render() {
    let entity = null;
    let entityName;

    const headerButton = (
      <button
        type="button"
        onClick={() =>
          this.setState({
            showDownloadModal: true
          })
        }
        className="button width-240 mr-2"
      >
        <i className="fa fa-fw fa-download" /> Download
      </button>
    );

    if (this.props.account) {
      entity = this.props.account;
      entityName = 'ACCOUNT';
    }
    if (this.props.card) {
      entity = this.props.card;
      entityName = 'PAYMENT_DEVICE';
    }
    if (this.props.user) {
      entity = this.props.user;
      entityName = 'USER';
    }

    let filter = [
      // {
      //   name: 'reqData',
      //   type: 'term',
      //   label: 'Request data'
      // },
      // {
      //   name: 'eventName',
      //   type: 'term',
      //   label: 'Event name'
      // },
      {
        name: 'url',
        type: 'term',
        label: 'URL'
      },
      {
        name: 'ipAddress',
        type: 'term',
        label: 'IP address'
      },
      {
        name: 'presentationStyle',
        type: 'select',
        field: 'presentationStyle',
        label: 'Risk level',
        style: { minWidth: 230 },
        options: {
          empty: '',
          placeholder: 'Select risk level',
          disableUnderline: false,
          options: [
            {
              id: 'LOW',
              name: 'LOW'
            },
            {
              id: 'MEDIUM',
              name: 'MEDIUM'
            },
            {
              id: 'HIGH',
              name: 'HIGH'
            }
          ]
        }
      },
      {
        name: 'user',
        type: 'term',
        label: 'User'
      },
      {
        name: 'fromDate',
        type: 'datetime',
        field: 'created',
        operation: 'gte',
        label: 'From Date'
      },
      {
        name: 'toDate',
        type: 'datetime',
        field: 'created',
        operation: 'lte',
        label: 'To Date'
      }
    ];

    if (this.props.user) {
      filter = filter.filter(item => item.name !== 'user');
    }

    const inner = (
      <>
        <AppTable
          icon="file-text"
          title="Events"
          onInit={tableComponent => {
            this._tableComponent = tableComponent;
          }}
          headerActions={headerButton}
          params={{
            entity: entityName,
            entityId: entity ? entity.id : undefined,
            accountId: this.props.account ? this.props.account.id : undefined,
            paymentDeviceId: this.props.card ? this.props.card.id : undefined,
            userId: this.props.user ? this.props.user.id : undefined
          }}
          filter={filter}
          model={EventsModel}
          renderCell={(column, item, index) => {
            switch (column.name) {
              case 'logTimestamp':
                return moment
                  .unix(item.logTimestamp / 1000)
                  .format('DD/MM/YYYY HH:mm');
            }
          }}
          getColumnActions={(event, index) => {
            return (
              <div>
                <Link
                  to={{
                    pathname: `/events/details/${event.id}`,
                    props: { entity, entityName }
                  }}
                  className="button-info"
                >
                  Details
                </Link>
              </div>
            );
          }}
        />
        <ModalDialog
          show={this.state.showDownloadModal}
          onClose={() => this.onCloseDownloadModal()}
          customContentStyle="remove-overflow"
        >
          <EventsDownload
            afterDownloadReport={() => this.onCloseDownloadModal()}
            closeDownloadReport={() => this.onCloseDownloadModal()}
            filter={this._tableComponent && this._tableComponent.getFilter()}
            entity={entityName}
            entityId={entity ? entity.id : undefined}
            accountId={this.props.account ? this.props.account.id : undefined}
            paymentDeviceId={this.props.card ? this.props.card.id : undefined}
            userId={this.props.user ? this.props.user.id : undefined}
          />
        </ModalDialog>
      </>
    );

    if (this.props.justTable) return inner;

    return (
      <div className="dashboard-management">
        <h1>
          <i className="fa fa-file-text" aria-hidden="true" /> Events
        </h1>
        {inner}
      </div>
    );
  }
}
