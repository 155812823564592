import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import DeleteButton from '@launchpad/components/crud/DeleteButton';
import { date } from '../../../../../../@launchpad/util/DateHelper';
import { LPOverridable } from '../../../../../../@launchpad/components';
import UsersModel from '../../models/UsersModel';
import { LoadingOverlay } from '../../../../../../@launchpad/index';

export default class CustomerBasicInfo extends Component {
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      redirect: false
    };
  }

  onApproveUser(id) {
    new UsersModel()
      .approveRejactKYC(id, 'VERIFIED')
      .then(() => {
        this.props.onRefresh();
      })
      .catch(error => {
        console.log(error);
      });
  }

  onTriggerPasswordChange(id) {
    new UsersModel()
      .triggerPasswordChange(id)
      .then(() => {
        this.props.onRefresh();
      })
      .catch(error => {
        console.log(error);
      });
  }

  onRejectUser(id) {
    new UsersModel()
      .approveRejactKYC(id, 'REJECTED')
      .then(() => {
        this.props.onRefresh();
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const { user } = this.props;

    if (this.state.redirect) {
      return <Redirect to={`/user-details/${user.id}`} />;
    }
    const kycStatus = user.kycStatus || '';
    const newKycStatus = kycStatus.replace(/#|_/g, ' ');

    return (
      <div className="main" key={user}>
        <div className="dark-blur" />
        <div className="customers-info">
          <div className="customer-details">
            <div>
              <h2 className="customer-name">
                <span className="company">
                  {user.firstName} {user.lastName}
                </span>
              </h2>
            </div>
            {/* <div>
              <div className="bottom" style={{ alignItems: 'center' }}>
                <Link
                  to={`/users/all-users/edit-user/${user.id}`}
                  className="button-info"
                  style={{ marginRight: 20 }}
                >
                  Edit
                </Link>
              </div>
            </div> */}
          </div>

          <div className="customers-main">
            <div className="row">
              <div className="col">
                {!user.id ? (
                  <LoadingOverlay />
                ) : (
                  <div className="padded">
                    <LPOverridable
                      name="user.details.basic.before"
                      user={user}
                    />
                    <div className="row text-white">
                      <div style={{ marginBottom: 20 }} className="col-lg-6">
                        <h4>Basic Info</h4>
                        <hr />
                        <dl>
                          <dt>Title:</dt>
                          <dd>{user.title || '-'}</dd>
                          <dt>First name:</dt>
                          <dd>{user.firstName}</dd>
                          <dt>Last name:</dt>
                          <dd>{user.lastName}</dd>
                          <dt>Username:</dt>
                          <dd>{user.username ? user.username : '-'}</dd>
                          <dt>E-mail address:</dt>
                          <dd>{user.email}</dd>
                          <dt>Customer id:</dt>
                          <dd>{user.id}</dd>
                          <dt>Birth date:</dt>
                          <dd>{date(user.birthDate, 'DD / MMMM / Y')}</dd>
                          <dt>Onboarding:</dt>
                          <dd>
                            {date(user.registeredAt, 'DD / MMMM / Y HH:mm')}
                          </dd>
                          <dt>Status:</dt>
                          <dd>{user.status || '-'}</dd>
                        </dl>
                        <div className="row">
                          <div className="col-sm-12 text-right">
                            <DeleteButton
                              btnText="Trigger Password Change"
                              className="button-info"
                              onConfirm={() =>
                                this.onTriggerPasswordChange(user.id)
                              }
                              text={`Are you sure you wish to send new password to "${`${user.firstName} ${user.lastName}`}"?`}
                            />
                            </div>
                        </div>
                      </div>
                      <div style={{ marginBottom: 20 }} className="col-lg-6">
                        <h4>KYC</h4>
                        <hr />
                        <dl>
                          <dt>Status:</dt>
                          <dd>{newKycStatus.toUpperCase()}</dd>
                        </dl>
                        <div
                          style={{ maxWidth: 200, margin: 'auto 0 auto auto' }}
                        >
                          {user.kycStatus === 'verified' ? (
                            <DeleteButton
                              btnText="Reject"
                              block
                              onConfirm={() => this.onRejectUser(user.id)}
                              text={`Are you sure you wish to reject this user "${`${user.firstName} ${user.lastName}`}"?`}
                            />
                          ) : (
                            <DeleteButton
                              btnText="Approve"
                              block
                              onConfirm={() => this.onApproveUser(user.id)}
                              text={`Are you sure you wish to approve this user "${`${user.firstName} ${user.lastName}`}"?`}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row text-white">
                      <div style={{ marginBottom: 20 }} className="col-lg-6">
                        <h4>Address Info</h4>
                        <hr />
                        <dl>
                          <dt>Address Line One:</dt>
                          <dd>{user.addressOne}</dd>
                          <dt>Address Line Two:</dt>
                          <dd>{user.addressTwo}</dd>
                          <dt>City:</dt>
                          <dd>{user.city}</dd>
                          <dt>Postcode:</dt>
                          <dd>{user.postCode}</dd>
                          <dt>Country code:</dt>
                          <dd>{user.countryCode}</dd>
                          <dt>Mobile:</dt>
                          <dd>{user.mobile}</dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
