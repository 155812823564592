import { AppRoute } from '@launchpad/components';
import BaseModule from '@launchpad/logic/module/BaseModule';
import AddUserGroup from '@launchpad/modules/push/user-groups/AddUserGroup';
import UserGroupDetails from '@launchpad/modules/push/user-groups/UserGroupDetails';
import UserGroupEdit from '@launchpad/modules/push/user-groups/UserGroupEdit';
import UserGroupsList from '@launchpad/modules/push/user-groups/UserGroupsList';
import React from 'react';

export const NAME = 'notifications';

export default class NotificationsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'notifications.*';
  }

  getPolicy() {
    return 'Admin:Notification:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'Notifications',
        icon: 'bell',
        // path: '/push-notifications',
        permission: 'notifications.*',
        policy: 'Admin:Notification:View:*',
        children: [
          // {
          //   label: 'Push dashboard',
          //   path: '/push-dashboard',
          //   icon: 'dashboard',
          //   permission: 'notifications.dashboard.*',
          //   policy: 'Admin:Notification:View:*',
          // },
          {
            label: 'Send new notification',
            path: '/push-notifications/add-new',
            icon: 'plus',
            permission: 'notifications.send.*',
            policy: 'Admin:Notification:View:*'
          },
          {
            label: 'Sent notifications',
            path: '/push-notifications',
            icon: 'paper-plane',
            permission: 'notifications.sent.*',
            policy: 'Admin:Notification:View:*'
          },
          {
            label: 'User Groups',
            path: '/user-groups',
            icon: 'users',
            permission: 'notifications.groups.*',
            policy: 'Admin:Notification:View:*'
          }
        ]
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      // <AppRoute
      //   path={`${currentUrl}push-dashboard`}
      //   component={PushDashboard}
      // />,
      <AppRoute
        path={`${currentUrl}push-notifications`}
        load={() =>
          import('../../../../@launchpad/modules/push/PushNotificationsPage')
        }
        permission="notifications.*"
      />,
      <AppRoute
        path={`${currentUrl}user-groups/add`}
        component={AddUserGroup}
        permission="notifications.send.*"
      />,
      <AppRoute
        path={`${currentUrl}user-groups/details/:id`}
        component={UserGroupDetails}
        permission="notifications.groups.*"
      />,
      <AppRoute
        path={`${currentUrl}user-groups/edit/:id`}
        component={UserGroupEdit}
        permission="notifications.groups.*"
      />,
      <AppRoute
        path={`${currentUrl}user-groups`}
        exact
        component={UserGroupsList}
        permission="notifications.groups.*"
      />
    ];
  }
}
